<template>
  <b-card>
    <h3>{{ $t('Weekly Work Status') }}</h3>
    <b-row class="mt-2">
      <b-col md="6">
        <h5 class="font-weight-bold">{{ $t('Escolha um intervalo de até 7 dias') }}</h5>
        <div class="mb-50">
          <div class="input-group">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <feather-icon icon="CalendarIcon"/>
                    </span>
            </div>
            <flat-pickr  v-model="selectedDates" class="form-control" :config="pickerConfig"/>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="d-flex mt-sm-1 mt-md-2">
          <div class="d-flex align-items-center">
            <b-button @click="generateReport"  variant="primary">{{ $t('Generate Report') }}</b-button>
          </div>
          <div class="d-flex align-items-center">
            <b-button @click="exportToExcel" class=" ml-50" variant="info"> <b-spinner class="mr-50" v-if="isExporting" small/> {{ $t('Export Sheet') }}</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col md="6">
        <div class="container mt-1">
          <div><p class="font-weight-bolder mb-1">Legenda</p></div>
          <div class="d-flex justify-content-around align-items-center">
            <div class="status">
              <span class="dot dot-off"></span>
              <span class="font-weight-bolder">Desligado</span>
            </div>
            <div class="status">
              <span class="dot dot-on"></span>
              <span class="font-weight-bolder">Ligado</span>
            </div>
            <div class="status">
              <span class="dot dot-no-connection"></span>
              <span class="font-weight-bolder">Sem Conexão</span>
            </div>
            <div class="status">
              <span class="dot dot-idle"></span>
              <span class="font-weight-bolder">Uso Ocioso</span>
            </div>
            <div class="status">
              <span class="dot dot-severe-use"></span>
              <span class="font-weight-bolder">Uso Severo</span>
            </div>
            <div class="status">
              <span class="dot dot-failure"></span>
              <span class="font-weight-bolder">Falha</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">
        
        <div id="chart" v-if="!isLoading">
          <vue-apex-charts ref="chart" type="rangeBar" height="300" :options="chartOptions"
                           :series="series"></vue-apex-charts>
        </div>
        <div v-if="isLoading">
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered"/>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>


import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BNavItemDropdown,
  BDropdownItem,
  BImg,
  BAvatar,
  BPagination,
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BSpinner
} from 'bootstrap-vue'
import {ref, onUnmounted} from '@vue/composition-api'

import router from '@/router'

import store from '@/store'

import queriesStoreModule from '../queriesStoreModule'

import flatPickr from 'vue-flatpickr-component'

import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar,
    BPagination,
    BIcon,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    flatPickr,
    VueApexCharts,


  },
  data() {
    return {

      isExporting: false,
      isLoading: false,
      selectedDates: '',
      pickerConfig: {
        mode: 'range',
        //minDate: new Date().fp_incr(-7), // Permite selecionar até 7 dias antes da data atual
        maxDate: new Date(), // A data máxima é a data atual

      },

      datesInRange: [],


      series: [{data: []}],
      chartOptions: {
        chart: {
          height: 300,
          type: 'rangeBar'
        },

        legend: {
          show: false,
          //position: 'bottom'
        },

        plotOptions: {
          bar: {
            horizontal: true,
            //barHeight: '50%',
            //rangeBarGroupRows: true
          },
        },

        xaxis: {
          type: "datetime",
          labels: {
            //datetimeUTC: false,
            format: 'HH:mm',
          },
        },
        yaxis: {
          type: "category",
        },
        tooltip: {
          custom(data) {
            const hourStart = String(new Date(data.y1).getUTCHours()).padStart(2, "0");

            const minuteStart = String(new Date(data.y1).getUTCMinutes()).padStart(2, "0");

            const hourEnd = String(new Date(data.y2).getUTCHours()).padStart(2, "0");
            const minuteEnd = String(new Date(data.y2).getUTCMinutes()).padStart(2, "0");
            //const values = data.ctx.rangeBar.getTooltipValues(data);
            return `${'<div class="px-1 py-50"><span>'}${
                hourStart + ":" + minuteStart + " to " + hourEnd + ":" + minuteEnd
            }</span></div>`
          },
        },
      },
    }
  },
  mounted() {

    this.getStartDate()
    this.generateReport()
  },
  watch: {},
  methods: {

    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },

    ensureAllowedDateRange(startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // Um dia em milissegundos
      const start = new Date(startDate);
      const end = new Date(endDate);
      if ((end - start) / oneDay <= 7) {
        console.log('RETORNOU TRUE ...')
        return true
      }
      console.log('RETORNOU FALSE ...')
      return false
    },

    async exportToExcel() {
      var dates = this.getDates()

      this.isExporting = true

      if (this.ensureAllowedDateRange(dates[0], dates[1])) {
        await store.dispatch('app-queries/exportWeeklyWorksatusToExcel', {
          startDate: dates[0],
          endDate: dates[1],
          assetId: router.currentRoute.params.id,
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$t('workstatus')} (${new Date()}).xls`)
          document.body.appendChild(link)
          link.click()
          this.isExporting = false
        }).catch(err => {
          console.log(err)
          this.isExporting = false
          this.showSnackbar({
            title: this.$t('Error exporting report'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          })
        })
      }else{
        this.isExporting = false
        this.showSnackbar({
          title: this.$t('Range not accepted. The interval must be 7 days or less.'),
          icon: 'AlertTriangleIcon',
          variant: 'info'
        })
      }
    },

    getDates() {
      if (this.selectedDates.includes("to")) {
        const datesArray = this.selectedDates.split(" to ");
        const startDate = new Date(datesArray[0]);
        const endDate = new Date(datesArray[1]);
        //return [startDate, endDate]
        return datesArray
      }
    },

    resolveColor(status) {

      if (status === 'failure') return '#ea5455'
      if (status === 'off') return '#82868b'
      if (status === 'noconnection') return '#f3f2f7'
      if (status === 'severe') return '#ff9f43'
      if (status === 'iddle') return '#00cfe8'
      if (status === 'on') return '#28c76f'
      return '#28c76f'
    },

    getStartDate() {
      // Obter a data de hoje
      const today = new Date();

      // Subtrair 7 dias da data de hoje
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      // Formatar as datas no formato desejado (YYYY-MM-DD)
      const todayFormatted = today.toISOString().slice(0, 10);
      const sevenDaysAgoFormatted = sevenDaysAgo.toISOString().slice(0, 10);

      // Criar a string final
      const dateString = `${sevenDaysAgoFormatted} to ${todayFormatted}`;

      this.selectedDates = dateString
    },

    getMonthAndDay(dt) {
      const date = new Date(dt);

      const day = date.getDate();
      const month = date.getMonth() + 1;

      const formattedDay = String(day).padStart(2, '0');
      const formattedMonth = String(month).padStart(2, '0');

      const formattedDate = `${formattedDay}/${formattedMonth}`;

      return formattedDate
    },

    fetchReport(date) {
      //this.isLoading = true
      if (date.includes('Z')) {
        date = date.slice(0, -1)
      }
      return store
          .dispatch('app-queries/fetchAssetWorkstatusHistory', {
            id: router.currentRoute.params.id,
            dt: date

          })
          .then(response => {

            //dataWorkStatus.value = [];

            return response.data.data

          })
          .catch((e) => {
            this.isLoading = false
            this.showSnackbar({
              title: this.$t("Error fetching report"),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            })

          })
    },

    utcToLocalDate(date) {

      var myDate = new Date(2023, 9, 7, date.getHours(), date.getMinutes())
      //var myDate = date


      var offset = myDate.getTimezoneOffset() * 60 * 1000;

      var withOffset = myDate.getTime();
      var withoutOffset = withOffset - offset;

      return withoutOffset;
    },

    // utcToLocalDate(utcDate) {

    //     // Defina o deslocamento horário de Brasília em minutos (-3 horas)
    //     const offsetBrasilia = -3 * 60;

    //     // Crie um novo objeto de data com o deslocamento aplicado
    //     const localDate = new Date(utcDate.getTime() + offsetBrasilia * 60 * 1000);

    //     localDate.setFullYear(2023)
    //     localDate.setMonth(7)
    //     localDate.setDate(9)

    //     console.log("localDate: ", localDate)


    //     return localDate.getTime();
    // },

    generateReport() {

      this.series[0].data = []
      //this.series = []
      if (this.selectedDates.includes("to")) {
        const datesArray = this.selectedDates.split(" to ");
        const startDate = new Date(datesArray[0]);
        const endDate = new Date(datesArray[1]);
        this.getDatesInRange(startDate, endDate)
      }
    },


    async getDatesInRange(startDate, endDate) {

      this.datesInRange = [];
      const oneDay = 24 * 60 * 60 * 1000; // Um dia em milissegundos

      const start = new Date(startDate);
      const end = new Date(endDate);

      // Verifica se o intervalo tem 7 dias ou menos
      if ((end - start) / oneDay <= 7) {
        // Cria as datas para cada dia do intervalo
        for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
          this.datesInRange.push(new Date(date));
        }
        if (this.datesInRange) {
          this.isLoading = true
          //ordena as datas
          this.datesInRange.sort((a, b) => b - a);

          const isoDates = this.datesInRange.map(date => date.toISOString());

          const chartDataPromises = isoDates.map(isoDate => this.fetchReport(isoDate));
          const chartData = await Promise.all(chartDataPromises);


          var self = this
          chartData.map(arrDay => {
            arrDay.map(dataItem => {
              console.log("dataItem: ", dataItem)
              self.series[0].data.push({
                x: self.getMonthAndDay(dataItem.end),
                y: [self.utcToLocalDate(new Date(dataItem.start)), self.utcToLocalDate(new Date(dataItem.end))],
                fillColor: self.resolveColor(dataItem.workStatus)
              })
            })

          })


          this.isLoading = false

        }


      } else {
        this.showSnackbar({
          title: this.$t('Range not accepted. The interval must be 7 days or less.'),
          icon: 'AlertTriangleIcon',
          variant: 'info'
        })
      }
    },

    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: icon,
        text: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },


  },
  setup() {

    const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'
    // Register module
    if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
    })

    const myId = ref(router.currentRoute.params.id)


    return {
      QUERIES_APP_STORE_MODULE_NAME,
      myId

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 5px;
}

.dot-off {
  background-color: #747679;
}

.dot-on {
  background-color: #47cd83;
}

.dot-no-connection {
  background-color: rgba(198, 217, 245, 0.85);
}

.dot-idle {
  background-color: #25d4ea;
}

.dot-severe-use {
  background-color: #feab5e;
}

.dot-failure {
  background-color: #ea5455;
}

</style>
    